.ding-Login {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e8e8e8;
}

.ding-dtflogin {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

@media screen and (max-width: 500px) {
  .ding-dtflogin {
    background-color: #fff !important;
  }
}
