/* stylelint-disable font-family-no-missing-generic-family-keyword */
.login {
  height: 100%;
  min-width: 320px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.title {
  margin: 0 auto 30px;
  text-align: center;
  color: #707070;
  height: 100px;

  .login-logo {
    object-fit: contain;
    height: 100%;
    width: 100%;
    display: block;
  }
}

.login-form {
  max-width: 300px;

  :global {
    .ant-input {
      font-size: 14px;
      height: 38px;
    }

    .ant-input-affix-wrapper {
      padding: 0 11px;
    }

    .ant-form-item-label .ant-form-item-required {
      font-size: 14px;
    }

    .ant-form-item {
      margin-bottom: 16px !important;
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;

    button {
      width: 48%;
    }
  }

  :global .ant-form-item-label > label {
    font-size: 14px;
  }

  .login-button {
    height: 50px;
    width: 100%;
    border: none;
    border-radius: 25px;
    color: #fff;
    font-size: 16px;
    /* stylelint-disable-next-line string-quotes */
    font-family: PingFangSC-Medium, 'PingFang SC';
    background-color: #f96f25;

    &:hover {
      background-color: #e66a28;
    }
  }

  .cancel-button {
    height: 40px;
    width: 100%;
    border: none;
    border-radius: 25px;
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
    /* stylelint-disable-next-line string-quotes */
    font-family: PingFangSC-Medium, 'PingFang SC';
    background-color: #d9d9d9;
  }
}

.login-code {
  height: 38px;
  display: flex;
  flex-direction: row-reverse;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.login-code-img {
  height: 38px;
}

.login-footer {
  position: absolute;
  bottom: 20px;
  text-align: center;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
  color: rgb(80, 83, 86);
  left: 69%;
  z-index: 2;
}

.watermark {
  position: absolute;
  bottom: 28px;
  right: 24px;
}

@media screen and (max-width: 500px) {
  .form-wrapper {
    height: 100%;
    width: 100%;
    display: block !important;
    z-index: 1;
    position: absolute;
    top: 35%;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 16px 16px 0px 0px;
    backdrop-filter: blur(8px);
  }

  .login {
    justify-content: center;
    align-items: flex-end;
    width: 100%;

    .company-info {
      position: absolute;
      bottom: 65%;
      left: 30px;
    }

    .login-block {
      width: 100%;
      min-width: 300px;
      padding: 0%;
      backdrop-filter: blur(0);
      background: transparent;
    }
    .subtitle {
      margin-bottom: 28px;
    }

    .login-form {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      margin-top: 36px;

      // .mobile-form-wrapper {
      //   display: block;
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   background-color: red;
      // }

      .update-form {
        .title {
          margin: 60px auto 0;
        }

        :global .ant-form-item {
          margin-bottom: 8px;

          .ant-form-item-label {
            padding: 0;
          }
        }
      }
    }
  }

  .remember {
    width: 100%;
    margin-bottom: 18px;
    height: 38px;
  }

  .login-footer {
    left: 50%;
    transform: translate(-50%, 0);
  }
  .system-lang {
    right: 16px !important;
  }

  .watermark {
    display: none;
  }
}

.login-logo-space {
  width: 400px;
  height: 100px;
}

.otherway-login-text {
  color: var(--tip-color) !important;
  width: 100%;
  font-size: 12px !important;
}

.otherway-login {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 44px;
  background: rgba(0, 116, 238, 0.15);
  border-radius: 25px;
  margin-bottom: 8px;

  > span {
    margin-left: 5px;
    color: var(--tip-color);
  }

  > span:hover {
    cursor: pointer;
    color: var(--primary-color);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
  }

  > svg:hover {
    cursor: pointer;
  }
}

.otherway-login:hover {
  background: rgba(0, 116, 238, 0.2);
  cursor: pointer;

  > span {
    cursor: pointer;
    color: var(--primary-color);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
  }

  > svg {
    cursor: pointer;
  }
}

.overlap-a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 32px;
  font-size: var(--basic-menu-font-size);
  font-weight: 400;
  color: #131e29;
  line-height: 32px;
  cursor: pointer;
  padding-left: 4px;
  margin: 0;

  &:hover {
    background-color: #f5f5f5;
  }
}

.checked {
  margin-right: 5px;
}

.user-info {
  font-size: var(--basic-nav-font-size);
  font-weight: 500;
  color: #131e29;
  line-height: 40px;

  :global {
    .ant-popover-title {
      padding-left: 16px;
      border-bottom: none;
    }

    .ant-popover-inner-content {
      padding: 0 12px 12px;
    }

    .ant-tabs-tab {
      font-size: var(--basic-menu-font-size) !important;
    }
  }
}

.right-menu-item {
  display: flex;
  align-items: center;
  column-gap: 6px;
  color: rgba(0, 100, 206, 1);
}

.login-block {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  width: 36%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 3% 0 5%;
  z-index: 2;
  min-width: 300px;

  .title-text {
    font-family: 'PingFangSC, PingFang SC';
    font-weight: 500;
    font-size: 24px;
    color: #131e29;
    margin-bottom: 13px;
  }
}

.system-lang {
  position: absolute;
  top: 25px;
  right: 60px;
  z-index: 2;
}

.center-img {
  position: absolute;
  height: 160%;
  z-index: 1;
  left: 5.5%;
  top: -29%;
}

.eimos-title-img {
  position: absolute;
  top: 24px;
  left: 36px;
  width: 12.3%;
  min-width: 92px;
  min-height: 24px;
}

.subtitle {
  font-family: PingFangSC, 'PingFang SC';
  font-size: 14px;
  color: rgb(100, 104, 108);
  margin-bottom: 50px;
}

.mobile-form-wrapper {
  display: none;
}
